import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import Bus from './bus';
// eslint-disable-next-line
import { BusEvents } from './Form.typings';
let Form = class Form extends Vue {
    constructor() {
        super(...arguments);
        this.fields = [];
    }
    onRulesChange() {
        this.fields.forEach((field) => {
            field.removeValidateEvents();
            field.addValidateEvents();
        });
    }
    created() {
        Bus.$off();
        Bus.$on(BusEvents.AddFields, this.addFields);
        Bus.$on(BusEvents.RemoveFields, this.removeFields);
    }
    /**
     * 添加FormItem组件
     */
    addFields(field) {
        if (field.prop) {
            this.fields.push(field);
        }
    }
    /**
     * 移除FormItem组件
     * */
    removeFields(field) {
        if (field.prop) {
            this.fields.splice(this.fields.indexOf(field), 1);
        }
    }
    /**
     * 验证当前字段
     */
    validate() {
        if (!this.model) {
            console.warn('[Element Warn][Form]model is required for validate to work!');
            return;
        }
        const isValid = [];
        this.fields.forEach((comp) => {
            if (comp.prop) {
                const res = comp.validate();
                isValid.push(res);
            }
        });
        return isValid.every((item) => !item);
    }
    /**
     * 清空验证规则
     */
    clearValidate(props = []) {
        const fields = props.length
            ? typeof props === 'string'
                ? this.fields.filter((field) => props === field.prop)
                : this.fields.filter((field) => props.indexOf(field.prop) > -1)
            : this.fields;
        fields.forEach((field) => {
            field.clearValidate();
        });
    }
    /**
     * 重置字段值
     */
    resetFields(props = []) {
        if (!this.model) {
            console.warn('[Element Warn][Form]model is required for resetFields to work.');
            return;
        }
        const fields = props.length
            ? typeof props === 'string'
                ? this.fields.filter((field) => props === field.prop)
                : this.fields.filter((field) => props.indexOf(field.prop) > -1)
            : this.fields;
        fields.forEach((field) => {
            field.resetField();
        });
    }
    /**
     * 验证指定字段的规则
     */
    validateField(props) {
        const fields = props.length
            ? typeof props === 'string'
                ? this.fields.filter((field) => props === field.prop)
                : this.fields.filter((field) => props.indexOf(field.prop) > -1)
            : this.fields;
        if (!fields.length) {
            console.warn('[Element Warn]please pass correct props!');
            return;
        }
        fields.forEach((field) => {
            field.validate('');
        });
    }
};
__decorate([
    Prop()
], Form.prototype, "model", void 0);
__decorate([
    Prop()
], Form.prototype, "rules", void 0);
__decorate([
    Prop({ default: false })
], Form.prototype, "inline", void 0);
__decorate([
    Prop({ default: 'left' })
], Form.prototype, "labelPosition", void 0);
__decorate([
    Prop({ default: 'left' })
], Form.prototype, "requiredPosition", void 0);
__decorate([
    Prop({ default: '80px' })
], Form.prototype, "labelWidth", void 0);
__decorate([
    Watch('rules')
], Form.prototype, "onRulesChange", null);
Form = __decorate([
    Component({
        name: 'cForm',
    })
], Form);
export default Form;
